import React from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from "gatsby";

const  PrevNext = ({ prev, next }) => {
  return (
    <div className={"flex w-full items-center my-5"}>
      <div className={" w-100 flex justify-start"}>
        {prev ? (
          <Link
            to={`/previous-projects/${prev}`}
            className={"flex items-center justify-between"}
          >
            <FontAwesomeIcon
              color={"#d6d6d6"}
              size={"2x"}
              icon={faChevronLeft}
            />
            <p className={"ml-5 font-exo2 uppercase text-epg-gray text-md "}>
              Previous
            </p>
          </Link>
        ) : null}
      </div>
      <div className={"w-full flex justify-end"}>
        {next ? (
          <Link
            to={`/previous-projects/${next}`}
            className={"flex items-center justify-between"}
          >
            <p className={"mr-5 font-exo2 uppercase text-epg-gray text-md "}>
              Next
            </p>
            <FontAwesomeIcon
              color={"#d6d6d6"}
              size={"2x"}
              icon={faChevronRight}
            />
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default PrevNext
