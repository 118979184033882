import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";

import background from "../../images/previousprojects/2.jpg"
const BorePanels = () => {
    return (
        <PageLayout
            noButton
            image={background}
            navSize={"h-96 xs:h-148 sm:h-116"}
            title={"Bore panel upgrades, ABB Water Master & Mag Flows"}
            titleStyles={"xl:mt-24 lg:mt-24 md:mt-20 sm:mt-20 xs:mt-16 mt-8"}
        >
            <Helmet>
                <title>
                    Bore panel upgrades, ABB Water Master &amp; Mag Flows, Siemens Pressure transducer installations | EPG Solutions
                </title>
                <meta
                    name="description"
                    content="Client: Power Water Completed: Ongoing Location: Stirling (Willora), Tara (Neutral Junction), Ali Curung Scope: Installation of ABB Water Masters, Siemens &nbsp;bore control panels, Miri telemetry installations and automation of sewerage systems. Challenges: Remote location."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={" py-5"}>
                    <div className={"px-2 mb-10 lg:leading-8 lg:text-lg"}>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Client:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Power Water
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Completed:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Ongoing
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Location:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Stirling (Willora), Tara (Neutral Junction), Ali Curung
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Scope:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Installation of ABB Water Masters, Siemens bore control panels,
              Miri telemetry installations and automation of sewerage systems.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Challenges:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Remote location
            </span>
                        </p>
                    </div>
                    <PrevNext
                        prev={"chlorine-containers-disinfection-program"}
                        next={"fuel-transfer-systems"}
                    />
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default BorePanels;
